import type { MouseEvent } from 'react';

import useTranslation from 'next-translate/useTranslation';

import type {
  DialogProps} from '@mui/material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import useCheckDevice from 'hooks/useCheckDevice';

interface UnfollowDialogProps extends Omit<DialogProps, 'onClose'> {
  onClickOkBtn: (e: MouseEvent) => void;
  onClose: (e: MouseEvent) => void;
}

const UnfollowDialog = ({
  open,
  onClickOkBtn,
  onClose,
}: UnfollowDialogProps) => {
  const { t } = useTranslation('scoreboard');
  const { isMobile } = useCheckDevice();

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant={isMobile ? 'h5Bold' : 'h4Bold'} component="p">
          {t('unfollowTitle')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography variant={isMobile ? 'body2' : 'body1'}>
          {t('unfollowMessage')}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button onClick={(e) => onClickOkBtn(e)}>{t('unfollow')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnfollowDialog;
