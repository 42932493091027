import { useEffect, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import type { ButtonProps } from '@mui/material';
import { Skeleton, useTheme } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import useAuthApi from 'api/hooks/useAuthApi';

import UnfollowDialog from 'components/organisms/UnfollowDialog';

import useActiveAuth from 'hooks/useActiveAuth';
import useQueryEvents from 'hooks/useQueryEvents';
import useTanstackQuery from 'hooks/useTanstackQuery';

import CustomSetButton from './CustomSetButton';

interface FollowButtonProps extends Omit<ButtonProps, 'color'> {
  channelOwnerUid: string;
  isMyChannel: boolean;
}

const FollowButton = ({
  channelOwnerUid,
  isMyChannel,
  sx,
  ...rest
}: FollowButtonProps) => {
  const { isProfileLoadProcessing, isAuthenticatedUser, isAnonymousUser } =
    useActiveAuth();
  const { followUser, getIsUserFollowing } = useAuthApi();
  const { t } = useTranslation('scoreboard');
  const { invalidateQueries } = useTanstackQuery();
  const {
    palette: { mode },
  } = useTheme();

  const [isFollow, setIsFollow] = useState<boolean>(false);
  const [isFollowLoading, setIsFollowLoading] = useState<boolean>(false);
  const [showUnfollowDialog, setShowUnfollowDialog] = useState(false);

  const { data: isInitFollow, isLoading } = useQuery({
    queryKey: ['follow', channelOwnerUid],
    queryFn: async () => await getIsUserFollowing(channelOwnerUid),
    enabled: isAuthenticatedUser,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });
  useQueryEvents(
    { data: isInitFollow },
    {
      onSuccess: (data) => setIsFollow(data),
    },
  );

  const changeFollowing = async () => {
    setIsFollowLoading(true);
    setIsFollow((prev) => !prev);
    await followUser(channelOwnerUid);
    invalidateQueries(['follow']);
    setIsFollowLoading(false);
  };

  const handleFollowBtnClick = async () => {
    if (isAnonymousUser) {
      enqueueSnackbar(t('errorMessage.loginRequire'), {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }

    try {
      if (isFollow) setShowUnfollowDialog(true);
      else await changeFollowing();
    } catch {
      enqueueSnackbar(t('errorMessage.followError'), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (isInitFollow !== null) {
      setIsFollow(isInitFollow);
    }
  }, [isInitFollow]);

  return isMyChannel ? null : (
    <>
      {isFollow !== null ? (
        <CustomSetButton
          className="gtm-channel-follow-btn"
          title={isFollow ? t('following') : t('follow')}
          isLoading={isLoading || isFollowLoading || isProfileLoadProcessing}
          disabled={isLoading || isFollowLoading}
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            await handleFollowBtnClick();
          }}
          sx={{
            width: '90px',
            px: 2,
            backgroundColor: isFollow ? 'transparent' : 'auto',
            border: isFollow ? '1px solid' : 'none',
            borderColor: isFollow
              ? mode === 'dark'
                ? 'deprecatedGray.99'
                : 'black'
              : 'transparent',
            color: isFollow ? 'themeInverseColor' : 'white',

            ':hover': {
              backgroundColor: isFollow
                ? mode === 'dark'
                  ? 'deprecatedGray.10'
                  : 'deprecatedGray.90'
                : 'primaryColor.600',
            },
            ...sx,
          }}
          {...rest}
        />
      ) : (
        <Skeleton
          variant="rounded"
          sx={{ width: '90px', height: '40px', ...sx }}
        />
      )}

      <UnfollowDialog
        onClickOkBtn={async (e) => {
          e.stopPropagation();
          setShowUnfollowDialog(false);
          await changeFollowing();
        }}
        open={showUnfollowDialog}
        onClose={(e) => {
          e.stopPropagation();
          setShowUnfollowDialog(false);
        }}
      />
    </>
  );
};

export default FollowButton;
