import useTranslation from 'next-translate/useTranslation';

import type {
  SxProps} from '@mui/material';
import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import FollowButton from 'components/atoms/FollowButton';
import { NextLinkComposed } from 'components/atoms/Link';
import SportsIcon from 'components/atoms/SportsIcon';

import useCheckDevice from 'hooks/useCheckDevice';
import useNumberSystemConverter from 'hooks/useNumberSystemConverter';

import type { ChannelListCardType } from 'types/channel';

const ChannelBanner = ({
  isMyChannel = false,
  channelData,
  sx,
}: {
  isMyChannel: boolean;
  channelData: ChannelListCardType;
  sx?: SxProps;
}) => {
  const { t } = useTranslation('scoreboard');
  const {
    breakpoints,
    palette: { deprecatedGray, gray, mode },
  } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Box
      className="gtm-channel-detail-btn"
      component={NextLinkComposed}
      to={{ pathname: `/channel/@${channelData.nickname}` }}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'default',
        gap: { xs: 0.5, sm: 2 },
        padding: 1.5,
        backgroundColor: mode === 'dark' ? deprecatedGray[5] : 'white',
        border: 'none',
        borderColor: mode === 'dark' ? deprecatedGray[20] : deprecatedGray[70],
        borderRadius: '6px',
        width: '100%',
        minWidth: { xs: '160px', sm: '100%' },
        cursor: 'pointer',
        alignItems: 'center',

        ':hover': {
          backgroundColor:
            mode === 'dark' ? deprecatedGray[10] : deprecatedGray[95],
        },
        ...sx,
      }}
    >
      <Avatar
        src={channelData.profileUrl ?? ''}
        alt={`${channelData.channelName}'s profile image`}
        sx={{
          height: { xs: '32px', sm: '48px' },
          width: { xs: '32px', sm: '48px' },
          my: 0.5,
          mx: { xs: 'auto', sm: 1 },
        }}
      />

      <Box sx={{ flex: 1, px: { xs: 1.5, sm: 0 } }}>
        <Typography
          component="h4"
          variant="body1Bold"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflowWrap: 'anywhere',
            wordBreak: 'break-all',
          }}
        >
          {channelData.channelName}
        </Typography>
        <Typography
          variant={isMobile ? 'overline' : 'body2'}
          sx={{ color: mode === 'dark' ? gray[500] : deprecatedGray[20] }}
        >
          {`${t('follower', {
            follower: useNumberSystemConverter(channelData.followerCount),
          })} | ${t('match', {
            match: useNumberSystemConverter(channelData.matchCount),
          })}`}
        </Typography>
      </Box>

      <FollowButton
        channelOwnerUid={channelData.uid}
        isMyChannel={isMyChannel}
        sx={{
          mx: 'auto',
          fontSize: { xs: '12px', sm: '16px' },
          width: { xs: 'auto', sm: '80px' },
          height: { xs: '36px', sm: '44px' },
          mr: { xs: 0, sm: 1 },
        }}
      />
    </Box>
  );
};

const ChannelCard = ({
  isMyChannel = false,
  channelData,
  summaryMode = false,
}: {
  isMyChannel: boolean;
  channelData: ChannelListCardType;
  summaryMode?: boolean;
}) => {
  const { t } = useTranslation('scoreboard');
  const {
    palette: { deprecatedGray, gray, mode },
  } = useTheme();
  const { isMobile, isPc } = useCheckDevice();

  const mainInterestSports = channelData.interests?.[0];

  return (
    <Box
      className="gtm-channel-detail-btn"
      component={NextLinkComposed}
      to={{ pathname: `/channel/@${channelData.nickname}` }}
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        textAlign: 'center',
        gap: 0.5,
        px: 2,
        py: { xs: 2, sm: 3, lg: 4 },
        backgroundColor: mode === 'dark' ? deprecatedGray[5] : 'white',
        border: `1px solid`,
        borderColor: mode === 'dark' ? deprecatedGray[20] : deprecatedGray[70],
        borderRadius: '6px',
        width: '100%',
        aspectRatio: '1 / 1.3',
        minWidth: summaryMode ? { xs: '160px', sm: '254px' } : '160px',
        maxWidth: summaryMode ? { xs: '160px', sm: '254px' } : 'none',
        cursor: 'pointer',
        alignItems: 'center',
        zIndex: 10,
        transition: '.3s',
        scrollSnapAlign: 'start',
        scrollSnapStop: 'normal',

        ...(isPc && {
          ':hover': {
            backgroundColor:
              mode === 'dark' ? deprecatedGray[10] : deprecatedGray[95],
            transform: 'translateY(-8px)',
            boxShadow:
              mode === 'dark'
                ? '0px 8px 16px rgba(120, 120, 120, 0.25)'
                : '0px 8px 16px rgba(0, 0, 0, 0.25)',
          },
        }),
      }}
    >
      <Avatar
        src={channelData.profileUrl ?? ''}
        alt={`${channelData.channelName}'s profile image`}
        sx={{
          height: { xs: '60px', sm: '96px' },
          width: { xs: '60px', sm: '96px' },
          mx: 'auto',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 0, sm: 1 },
          mt: 0.5,
        }}
      >
        <Typography
          component="h4"
          variant={isMobile ? 'body1Bold' : 'h4Bold'}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflowWrap: 'anywhere',
            wordBreak: 'break-all',
          }}
        >
          {channelData.channelName}
        </Typography>
        <Typography
          variant={isMobile ? 'body3' : 'body1'}
          sx={{ color: gray[500], whiteSpace: 'nowrap' }}
        >
          {`${t('follower', {
            follower: useNumberSystemConverter(channelData.followerCount),
          })} | ${t('match', {
            match: useNumberSystemConverter(channelData.matchCount),
          })}`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 0.5,
          height: '22px',
          mb: 0.5,
          flex: 1,
        }}
      >
        {mainInterestSports ? (
          <>
            <SportsIcon
              sportType={mainInterestSports}
              sx={{ width: '18px', height: '18px', color: gray[500] }}
            />
            <Typography
              variant={isMobile ? 'body3' : 'body1'}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflowWrap: 'anywhere',
                wordBreak: 'break-all',
                color: gray[500],
              }}
            >
              {t(`sportType.${mainInterestSports}`)}
            </Typography>
          </>
        ) : (
          <Box sx={{ height: isMobile ? '18px' : '24px' }} />
        )}
      </Box>
      <Box sx={{ height: { xs: '32px', sm: '40px' } }}>
        <FollowButton
          channelOwnerUid={channelData.uid}
          isMyChannel={isMyChannel}
          sx={{
            fontSize: { xs: '12px', sm: '14px' },
            height: { xs: '32px', sm: '40px' },
            width: { xs: '124px', sm: '148px' },
          }}
        />
      </Box>
    </Box>
  );
};

const PublicChannelListCard = {
  Banner: ChannelBanner,
  Card: ChannelCard,
};

export default PublicChannelListCard;
