import type {
  ButtonProps} from '@mui/material';
import {
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const CustomSetButton = ({
  title,
  disabled,
  isLoading,
  sx,
  ...buttonProps
}: { title: string; isLoading?: boolean } & ButtonProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={isLoading || disabled}
      sx={{
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...buttonProps}
    >
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <Typography variant={isMobile ? 'body2Bold' : 'body1Bold'}>
          {title}
        </Typography>
      )}
    </Button>
  );
};

export default CustomSetButton;
